// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getDatabase} from "firebase/database";
import { getAuth } from "firebase/auth";
// import {RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth"

const firebaseConfig = {
  apiKey:"AIzaSyDf5RXSkLMVrdWPHFay9DKUxn4TFTEJN2U",
  authDomain: "health-app-4c3da.firebaseapp.com",
  databaseURL: "https://health-app-4c3da-default-rtdb.firebaseio.com",
  projectId: "health-app-4c3da",
  storageBucket: "health-app-4c3da.appspot.com",
  messagingSenderId:  "604234265670",
  appId: "1:604234265670:web:0e482b162e44b611738647",
  measurementId: "G-8860BK7BFL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

// Initialize Firebase Auth
const auth = getAuth(app);
// auth.languageCode = 'en';

// Disable app verification for testing (only in development environment)
if (process.env.NODE_ENV === 'development') {
  auth.settings.appVerificationDisabledForTesting = true;
}

// export default db;
export { db, auth};
